
import React, { useContext } from 'react';
import { ThemeContext } from '../../theme/ThemeProvider';

const images = [
  "/Images/1img.png",
  "/Images/2 ims.png",
  "/Images/3 img.png",
  "/Images/4 img.png",
  "/Images/5 img.png",
  "/Images/6 img.png",
  "/Images/7 img.png",
  "/Images/8 img.png",
  "/Images/9 img.png"
];

const Gallery = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;
  return (
    <div className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
      } text-justify flex flex-col justify-center items-center py-10`}
    >
      <div className="mx-auto text-center py-2 mt-2">
        <h1 className="text-4xl font-bold mb-6 text-center text-blue-500 md:mt-4">Gallery</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 justify-items-center mb-4">
          {images.map((src, index) => (
            <img key={index} className="w-[45vh] h-[40vh] rounded-lg " src={src} alt='img' />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;

