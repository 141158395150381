import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";

const MarakaziDarulIfta = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;
    const markazidarulifta = require("../../../Images/Markazidaruliftar.png");
    return (
        <div
            className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
                } text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
                <div className="flex items-center justify-center mb-4">
                    <img src={markazidarulifta} alt="darulifta" width={200} height={200}></img>
                </div>
                <h2 className="font-greycliff-cf text-blue-500 mt-0 text-2xl md:text-3xl lg:text-2xl md:mb-5 font-bold">
                    INTRODUCTION TO MARKAZI DARUL IFTA, BAREILLY
                </h2>
                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The Markazi Darul Ifta, Bareilly, is an esteemed institution known
                        for its role in providing authoritative and reliable Islamic legal
                        Decrees, known as Fatwas. The foundation of this significant centre
                        of Islamic jurisprudence dates back to 1982, a period marked by the
                        passing of one of the most revered Islamic scholars of India, Huzoor
                        Mufti-e-Azam Hind, Alaihir-rahma. Following his demise, there was a
                        great need for a dedicated institution to manage the increasing
                        volume of religious inquiries from across the globe.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        This need was keenly felt by Hazrat Allama Mufti Mohammed Akhtar
                        Raza Khan Qadri, who is widely respected as Huzoor Taajush Shariah
                        Alaihir-rahma. As a direct descendant of the illustrious Ala Hazrat
                        Imam Ahmad Raza Khan Qadri Alaihir- rahma, Huzoor Taajush Shariah
                        Alaihir- rahma was inundated with requests for Fatwas from Muslims
                        seeking religious guidance. Realizing the importance of addressing
                        these queries with precision and scholarly rigor, he established the
                        Markazi Darul Ifta in 1982.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The Darul Ifta was initially established to alleviate the immense
                        burden of work that had fallen upon Huzoor Taajush Shariah due to
                        the sheer number of Fatwa requests, and his numerous other
                        responsibilities to the Muslim world. Muslims from various parts of
                        India and abroad began to rely heavily on his guidance for resolving
                        their religious and social issues. As the institution began its
                        operations, it became clear that it was filling a vital need within
                        the community.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        By 1984, just two years after its establishment, the Markazi Darul
                        Ifta witnessed a dramatic increase in the number of queries it
                        received. While some individuals personally visited the Darul Ifta
                        in Bareilly to seek answers to their questions, the majority of
                        inquiries arrived through letters sent via postal services. These
                        letters came not only from across India but also from international
                        locations, reflecting the widespread trust and reliance on the Darul
                        Ifta's verdicts.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        To manage this influx, Huzoor Taajush Shariah expanded the
                        institution's staff in 1984. This expansion was crucial to
                        maintaining the high standards of scholarly work that the Darul Ifta
                        was known for. The team of scholars worked diligently to ensure that
                        every question was answered with careful consideration, drawing upon
                        the rich heritage of Islamic jurisprudence.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        A significant period in the history of Markazi Darul Ifta was the
                        tenure of Hazrat Allama Mufti Qazi Abdul Rahim Alaihir-rahma, who
                        served as the Head Mufti from 1984 until 2010. Under his leadership,
                        the Darul Ifta grew not only in its capacity to handle a large
                        volume of queries but also in its reputation as a centre of Islamic
                        learning. Mufti Qazi Abdul Rahim was a scholar of great repute,
                        known for his deep knowledge and understanding of Islamic law. His
                        contributions ensured that the Darul Ifta continued to provide
                        clear, well-reasoned, and authoritative responses to the religious
                        inquiries it received. He was trusted by Huzoor Taajush Shariah
                        Alaihir-rahma, as he was trained under the watchful eye of Huzoor
                        Mufti-e-Azam Alaihir-Rahma in the science of Fatwa writing.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        During these years, the Markazi Darul Ifta played a crucial role in
                        guiding the Muslim community on a wide range of issues, from
                        personal matters to complex legal questions. Its Fatwas were sought
                        by individuals, organizations, and even other religious
                        institutions, underscoring its importance as a central authority in
                        Islamic jurisprudence.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Today, the Markazi Darul Ifta is managed under the watchful eye of
                        the Muslim Chief Justice of India, The Honourable Huzoor Mufti Asjad
                        Raza Khan Qadri who is the rightful and duly appointed successor and
                        heir of Huzoor Taajush Shariah Alaihir- rahma. We pray that the
                        Darul Ifta continues to serve its purpose in serving the Ummah of
                        The Beloved Nabi (Peace and Salutations upon Him).
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MarakaziDarulIfta;
