import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";

const JamateRazaMustafa = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;

    const jamatrazaemustafa = require("../../../Images/Razaemustafa.png");
    const listStyle: React.CSSProperties = {
        listStyleType: 'disc',
    };
    return (
        <div
            className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
                } text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
                <div className="flex items-center justify-center mb-4">
                    <img src={jamatrazaemustafa} alt="jamaterazamustafa" width={200} height={200}></img>
                </div>
                <h2 className="font-greycliff-cf text-blue-500 mt-0 text-3xl md:text-4xl lg:text-3xl md:mb-5 font-bold">
                    Jamat Raza-E-Mustafa
                </h2>
                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The great services of Aala Hazrat Imam Ahmad Raza Khan
                        Fazil-E-Barelvi are global. A versatile genius, leading scholar, and
                        eminent jurist of his time, he was a staunch upholder of the Holy
                        Prophet's (Sallallahu Alaihe Wasallam) traditions and a revivalist
                        of the present century. He devoted all his energy to the cause of
                        Deen-E-Mateen, safeguarding, protecting, and promoting the spirit of
                        Shariah, regardless of criticism from those who disagreed with his
                        interpretations of the ways of Allah. He shunned worldly pursuits
                        and instead focused on composing poetry in praise of the Holy
                        Prophet (Allah's Grace and Peace be upon him). The rewards for his
                        dedication in this world and the hereafter are beyond imagination.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Hazrat Fazil-E-Barelvi wrote over one thousand treatises,
                        exhaustively addressing every topic he touched. He followed the
                        patterns of renowned Islamic writers but excelled in explaining and
                        expanding on the most difficult and complicated subjects in
                        relatively few and simple words. During a time when India had become
                        a center of anti-Muslim movements, and many forces sought to remove
                        Islam and Muslims from the subcontinent, his work stood as a
                        significant bulwark against these challenges.
                    </p>

                    <div className="text-gray-500 mt-2 space-y-4 text-justify">
                        <h3 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-xl lg:text-2xl md:mb-5 font-bold">
                            Challenges
                        </h3>
                        <p className="text-sm sm:text-base md:text-lg lg:text-base">
                            In order to give Religious, social and economic stability to the
                            Muslim Ummah, Aala Hazrat Imam Ahmed Raza Qadri Muhad'dith Barelvi
                            Alaihir Rahma established Jamat Raza-e- Mustafa on the 7th Rabi ul
                            Aakhir 1339 Hijri co- inciding with 17th December 1920. One of the
                            clear aims of this movement, is to also refute the deviant sects,
                            and safeguard the beliefs (Aqaa'id) of the Muslims.
                        </p>
                        <p className="text-sm sm:text-base md:text-lg lg:text-base">
                            Jamat Raza-E-Mustafa has done various glorious achievement during
                            1921 to 1926 AD. In 1960 Jamat Raza-E-Mustafa had came to an end.
                            At last in October 1963 Huzoor Mufti-E- Azam Hind Alaihir Rahma
                            has re-established this organization and followings rules and
                            regulations were also made.
                        </p>
                        <ul style={listStyle} className="text-gray-500 mt-2 space-y-4 text-justify">
                            <li>
                                Huzoor Mufti-E-Azam Hind will be lifelong president of Jamat
                                Raza-e-Mustafa
                            </li>
                            <li>
                                Jamat Raza-E-Mustafa will establish and supervise all the
                                branches of Jamat at Local, District and state level.
                            </li>
                            <li>
                                All the Sunni institutions all over India and organizations of
                                the country will be under the Jamat Raza-E-Mustafa.
                            </li>
                            <li>
                                If any dispute will arise between any Sunni organization and the
                                other, Jamat Raza-e-Mustafa will work as a judiciary and solve
                                the dispute.
                            </li>
                        </ul>
                        <h3 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-xl lg:text-2xl md:mb-5 font-bold">
                            AIMS AND OBJECTS OF JAMAT RAZA-E-MUSTAFA

                        </h3>
                        <p className="text-sm sm:text-base md:text-lg lg:text-base">
                            Imam-E-Ahle Sunnat was a scholarly writer having vast knowledge and vision.
                            He had extensive knowledge and deep understanding of Hadith and Jurisprudence.
                            His Judicious opinions to various Queries from different scholars show unique
                            competence and comprehensive vision of thought, Quranic insight and ingenious.
                            There are some aims and objects of Jamat Raza-E-Mustafa.


                        </p>
                        <ul style={listStyle} className="text-gray-500 mt-2 space-y-4 text-justify">
                            <li>
                                Progress of Ahl-E-Sunnat wal Jamat especially Unity of Ahl-E-Sunnnat, progress of religion and development of knowledge of Ahl-E-Sunnat.
                            </li>
                            <li>
                                To work in the field of Success of the people of Ahl-E-Sunnat and establish Islamic educational centre.
                            </li>
                            <li>
                                Law: (Present and future) For the benefit of Ahl- E- Sunnat make an amendment of various law for this work do appeal and make sermon for the Government and members of Parliament and Assemblies.

                            </li>
                            <li>
                                To establish a huge Library, Having a huge quantity of Islamic books and contemporary books and journals and magazine etc.
                            </li>
                            <li>To work of the progress of Ahl-E-Sunnat in the cities, towns and as well as in rural areas.</li>
                            <li>To make brotherhood and unity between Muslims in every area of the country.</li>
                            <li>To establish the branches of Jamat in Tahsil, towns, cities (in local level).</li>
                            <li>To establish Darul Qaza and Darul Ifta (Hanfi sect of Ahl-E-Sunnat).</li>
                            <li>Supervise the property of Muslim Waqf, Masjid etc.</li>
                            <li>To work for the contributions of such money for the work above said to make members in the whole country and give training for its member</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JamateRazaMustafa;
