import React from 'react';

import { useContext } from 'react';
import { ThemeContext } from './theme/ThemeProvider';

export function TermsAndConditions() {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;

  return (
    <div
      className={` md:mx-auto min-h-[70vh] p-4 text-justify lg:px-72 lg:text-justify ${
        isDarkMode ? "bg-globalBg text-[#ffff]" : "bg-white text-black"
      }`}
    >
      <h2
        className={`font-extrabold mb-4 text-2xl lg:text-center md:text-center ${
          isDarkMode ? "text-blue-500" : "text-blue-400"
        }`}
      >
        Terms and Conditions
      </h2>
      <p className="text-sm mt-4">
        Welcome to Imam Ahmed Raza Trust - Islamic Trust For Donation. By accessing our website, making a donation, or using our services, you agree to comply with and be bound by the following Terms and Conditions. If you do not agree with these terms, please do not use our website or services.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        1. Donation Policy
      </h5>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Eligibility:{" "}
        </strong>
        By making a donation to Imam Ahmed Raza Trust, you confirm that you are legally capable of entering into a binding agreement.
      </p>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Acceptance:{" "}
        </strong>
        We reserve the right to refuse or cancel any donation for any reason, including but not limited to issues with payment processing, suspicion of fraudulent activity, or violation of our policies.
      </p>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Confirmation:{" "}
        </strong>
        Upon successful donation, you will receive a confirmation email. This email will serve as a receipt for your donation.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        2. User Accounts
      </h5>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Creation:{" "}
        </strong>
        If you create an account on our website, you are responsible for maintaining the confidentiality of your account information, including your password.
      </p>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Termination:{" "}
        </strong>
        We reserve the right to terminate or suspend your account at our discretion, without notice, if we believe you have violated these Terms and Conditions.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        3. Intellectual Property
      </h5>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Ownership:{" "}
        </strong>
        All content on our website, including but not limited to text, graphics, logos, and images, is the property of Imam Ahmed Raza Trust and is protected by copyright and other intellectual property laws.
      </p>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Use of Content:{" "}
        </strong>
        You may not reproduce, distribute, modify, or create derivative works from the content on our website without prior written consent from Imam Ahmed Raza Trust.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        4. Privacy Policy
      </h5>
      <p className="text-sm mt-2">
        Your use of our website and services is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        5. Limitation of Liability
      </h5>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Disclaimer:{" "}
        </strong>
        Imam Ahmed Raza Trust is not liable for any damages, losses, or expenses resulting from your use of our website or services.
      </p>
      <p className="text-sm mt-2">
        <strong className={`${isDarkMode ? "text-blue-400" : "text-black"}`}>
          Indemnification:{" "}
        </strong>
        You agree to indemnify and hold Imam Ahmed Raza Trust harmless from any claims, liabilities, or expenses arising from your use of our website or services.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        6. Governing Law
      </h5>
      <p className="text-sm mt-2">
        These Terms and Conditions are governed by the laws of India. Any legal actions arising from or related to these terms will be filed in the appropriate courts within India.
      </p>

      <h5
        className={`text-base font-semibold mt-6 ${
          isDarkMode ? "text-yellow-300" : "text-black"
        }`}
      >
        7. Changes to Terms and Conditions
      </h5>
      <p className="text-sm mt-2">
        We reserve the right to update or modify these Terms and Conditions at any time. Changes will be effective upon posting the revised terms on our website.
      </p>
      <p className="text-sm mb-6">
        By using our website or services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
      </p>
    </div>
  );
}

export default TermsAndConditions;
