import React, { useContext, useState } from 'react'
import { ThemeContext } from '../../theme/ThemeProvider';
import { NavLink } from 'react-router-dom';

const departmentsData =
    [
        {
            "name": "Centre of Islamic studies Jamiatur Raza",
            "image": "/Images/Centerofislamic.png",
            "link": "/jamiaturraza",
            "description": "At Jamiatur Raza, we believe that the education and schooling of the new generation are paramount for the benefit of mankind and the flourishing of Islamic followers. This noble mission has always been a priority for esteemed leaders such as Huzoor Mufti-e-Azam Hind and, later, Qazi-ul-Quzat Filhind Huzoor Tajush Shari'a Allama Akhtar Raza Khan Azhari."
        },

        {
            "name": "The Shar’ee Council of India",
            "image": "/Images/Shareecouncil.png",
            "link": "/sharaeecouncilindia",
            "description": "Sharee Council Of India was estab;lished by Huzoor Tajushsharia Allama Mufti Muhammad Akhtar Raza Khan Azhari Rahimahullah Ta'ala for providing the solution of burning topics of society,which violate the Islamic Shari'ah"
        },
        {
            "name": "Jamat Raza-e-Mustafa",
            "image": "/Images/Razaemustafa.png",
            "link": "/jamaterazamustafa",
            "description": "Jamat Raza-e Mustafa Was established in 1920 by Aala Hazrath Imam Ahmad Raza Khan.This Jamat is Working under the spritual guidance if Imam Ahmad Raza Khan.Huzoor Mufti-e-Aazam-e-Hind and Huzoor Taajush shari'ah.At presnt.Allama Mufti Muhammad Asjad Raza Khan Qadri Hafidahullahu Ta'ala/Urdu word hear is leading the Jamat"
        },
        {
            "name": "Monthly Sunni Duniya",
            "image": "/Images/Monthlysunniduniya.png",
            "link": "/sunniduniya",
            "description": "It is vital for the growth and expansion of any organization or movement that it has its own managed and organized media. Without which, the duties of propagation cannot be performed effectively. Otherwise, the message of any movement or an organization would not be long lasting and consistent.”"
        },
        {
            "name": "Markazi Darul Ifta",
            "image": "/Images/Markazidaruliftar.png",
            "link": "/markazidarulifta",
            "description": "The Markazi Darul Ifta, Bareilly, is an esteemed institution known for its role in providing authoritative and reliable Islamic legal Decrees, known as Fatwas. The foundation of this significant centre of Islamic jurisprudence dates back to 1982, a period marked by the passing of one of the most revered Islamic scholars of India, Huzoor Mufti-e-Azam Hind, Alaihir-rahma."
        }

    ]

const SeeAllDepartments = () => {
    const [expandState, setExpandState] = useState({});
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;


    const toggleExpansion = (index) => {
        setExpandState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const departmentCards = departmentsData.map((department, index) => {
        const isExpanded = expandState[index];
        const truncatedDescription = department.description.substring(0, 200);
        const description = isExpanded ? department.description : truncatedDescription;

        return (
            <div
                key={index}
                className={`rounded-lg overflow-hidden shadow-md hover:scale-105 transition-transform duration-150 p-3 ${isDarkMode ? "bg-[#2e2e2e]" : ""}`}
            >
                <div className="relative flex fex-col justify-center items-center">
                    <img
                        src={department.image}
                        alt={department.name}
                        className="w-56 mt-3 h-40 object-contain rounded-md"
                    />
                </div>

                <div className="flex flex-col">
                    <h2 className={`mt-2 text-lg font-semibold text-center ${isDarkMode ? "text-[#afaea9]" : ""}`}>
                        {department.name}
                    </h2>

                    <p className="mb-2 mt-2 text-sm text-gray-600">
                        {description}
                        {department.description.length > 200 && (
                            <button
                                onClick={() => toggleExpansion(index)}
                                className="text-blue-500 hover:underline ml-2"
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </button>
                        )}
                    </p>
                </div>

                <div className="mt-auto flex flex-col justify-between gap-4 p-3">
                    <NavLink
                        to={department.link}
                        className="block text-center w-full bg-blue-400 text-white py-2 rounded hover:bg-blue-700"
                    >
                        Show More
                    </NavLink>
                </div>
            </div>
        );
    });

    return (
        <div className={`${isDarkMode ? "bg-globalBg" : "bg-white"}`}>
            <div className="py-8">
                <h1 className="text-4xl font-bold text-center text-blue-400 mb-8">Our Departments</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 lg:mx-72 md:px-3 px-2">
                    {departmentCards}
                </div>
            </div>
        </div>
    );
}

export default SeeAllDepartments

