import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "../src/components/hooks/redux/store";
import ThemeProvider from "./components/theme/ThemeProvider";
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <App />
        <Toaster/>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
