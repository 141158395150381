import { useContext, useState } from 'react'
import projectData from "../../../Json Data/OurProjects.json"
import { ThemeContext } from '../../theme/ThemeProvider';


const OurProjects = () => {

    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    const { isDarkMode } = themeContext;
    const [expandState, setExpandState] = useState({});

    const toggleExpansion = (index) => {
        setExpandState((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };


    const cards = projectData.map((article, index) => {
        const isExpanded = expandState[index];
        const truncatedDescription = article.description.substring(0, 200);
        const description = isExpanded ? article.description : truncatedDescription;

        return (
            <div
                key={article.title}
                className={`rounded-lg overflow-hidden shadow-md hover:scale-105 transition-transform duration-150 p-3 ${isDarkMode ? "bg-[#2e2e2e]" : ""}`}
            >
                <div className="relative flex flex-col justify-center items-center h-44">
                    <img
                        src={article.image}
                        alt={article.title}
                        className="w-full h-full max-h-48 object-cover rounded-md"
                    />
                    <span className="absolute top-2 right-3 bg-gradient-to-r from-yellow-400 to-red-500 text-white text-xs font-medium py-0.5 px-3 rounded-3xl">
                        {article.Badge}
                    </span>
                </div>

                <div className="flex flex-col ">
                    <h2 className={`mt-2 text-lg font-semibold text-center ${isDarkMode ? "text-[#afaea9]" : ""}`}>
                        {article.title}
                    </h2>

                    <p className="mb-2 lg:min-h-[100px] md:min-h-[160px] text-sm text-gray-600">
                        {description}
                        {article.description.length > 200 && (
                            <button
                                onClick={() => toggleExpansion(index)}
                                className="text-blue-500 hover:underline ml-2"
                            >
                                {isExpanded ? "Read Less" : "Read More"}
                            </button>
                        )}
                    </p>
                </div>

                <div className="mt-auto flex flex-col justify-between gap-4 p-3">
                    <a
                        href={article.mainlink}
                        className="block text-center w-full bg-gray-700 text-white py-2 rounded hover:bg-gray-800"
                    >
                        Show More
                    </a>
                    <a
                        href={article.to}
                        className="block text-center w-full bg-blue-400 text-white py-2 rounded hover:bg-blue-500"
                    >
                        Donate
                    </a>
                </div>
            </div>
        );
    });


    return (
        <div className={`${isDarkMode ? "bg-globalBg" : "bg-white"}`}>
            <div className="py-8">
                <h2 className="text-3xl font-bold text-center text-blue-400 mb-8">Our Projects</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 lg:mx-72 md:px-3 px-2">
                    {cards}
                </div>
            </div>
        </div>
    );
}

export default OurProjects