import React, { useContext, useEffect, useState } from "react";
import "../App.css";

import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import {
  ContactUs,
  Home,
  Course,
  CourseForm,
  CourseType,
  CourseTypeForm,
  CourseOffering,
  CourseOfferingForm,
  EducationType,
  EducationTypeForm,
  Registration,
  DonationTypePurpose,
  DonationTypePurposeForm,
  Expense,
  ExpenseForm,
  DonationDetails,
  DonationDetailForm,
  BankDetails,
  BankDetailsForm,
  BalanceTransfer,
  BalanceTransferForm,
  Box,
  BoxForm
} from "@11solutionspvtltd/eleven-solutions-trust-website";

import {
  Header,
  Login,
  Footer,
  getProfileApi,
  TaxonomyForm,
  UserForm,
  Users,
  Taxionomies,
  Template,
  TemplateFrom,
} from "@11solutionspvtltd/eleven-solutions-common-website";

import About from "../components/about/About";
import AdminSideBar from "../components/admin/AdminSideBar";
import { setUserProfile } from "../components/hooks/redux/slices/userProfileSlice";
import { ThemeContext } from "../components/theme/ThemeProvider";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../components/hooks/redux/redux";
import Cookies from "universal-cookie";
import SeeAllDepartments from "../components/pages/ourPages/SeeAllDepartments";
import departmentsData from "../../src/Json Data/Ourdepartments.json";
import WhyChooseus from "../../src/Json Data/WhyChooseus.json"
import partnerships from "../../src/Json Data/Partnerships.json"
import bankaccounts from "../Json Data/Bankaccounts.json"
import projectData from "../../src/Json Data/OurProjects.json"
import { IconAccessPoint, IconBrandFacebook, IconBrandGoogleMaps, IconBrandInstagram, IconBrandTwitter, IconBrandWhatsapp, IconBrandYoutube, IconUser } from '@tabler/icons-react';
import Gallery from "../components/pages/ourPages/Gallery";
import JamateRazaMustafa from "../components/pages/ourPages/JamateRazaMustafa";
import SharaeeCouncilIndia from "../components/pages/ourPages/SharaeeCouncilIndia";
import MarakaziDarulIfta from "../components/pages/ourPages/MarakaziDarulIfta";
import MonthlySunniDuniya from "../components/pages/ourPages/MonthlySunniDuniya";
import JamiaturRaza from "../components/pages/ourPages/JamiaturRaza";
import OurProjects from "../components/pages/ourPages/OurProjects";
import HamidiMasjid from "../components/pages/ourPages/HamidiMasjid";
import QuadriHostel from "../components/pages/ourPages/QuadriHostel";
import TermsAndConditions from "../components/TermsAndConditions";
import Privacy from "../components/Privacy";
import RefundPolicy from "../components/RefundPolicy";
import StudentRegistration from "../components/pages/homepages/StudentRegistration";
import OnlineClass from "../components/pages/ourPages/OnlineClass";
// import CourseTypeForm2 from "../components/admin/CourseTypeForm";
// import CourseType2 from "../components/admin/CourseType";

const mockdata = [
  {
    title: "Our Location",
    description:
      "82, Dargah-e Aala Hazrat,Mohalla Saudagaran,Bareilly Sharif, Uttar Pradesh, India, POSTAL CODE - 243003",
    link1: 'https://www.google.com/maps/dir//Dargah-e-Aala+Hazrat+Bhoor+Bareilly,+Uttar+Pradesh+243003/@28.361932,79.4085728,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39a00730d4cad619:0xad84ad613cb0e596!2m2!1d79.4085728!2d28.361932?entry=ttu',
    icon: IconBrandGoogleMaps,
    icon2: IconAccessPoint,
    icon3: IconBrandTwitter,
    icon4: IconBrandInstagram,
    Icon5: IconBrandYoutube,
    Icon6: IconBrandFacebook,
  },
  {
    title: "Contact Us",
    description: 'Mobile: +91 9897007120, +91 581 2458543',
    link1: 'https://wa.me/+919897007120',
    link2: "mailto:ContactUs@ImamAhmadRazaTrust.org",
    icon: IconUser,
    icon2: IconAccessPoint,
    icon3: IconBrandTwitter,
    icon4: IconBrandInstagram,
    Icon5: IconBrandYoutube,
    Icon6: IconBrandFacebook,
  },
  {
    title: "Follow us",
    description: "",
    link3: 'https://x.com/imamahmadrazatr',
    link4: 'https://www.instagram.com/imamahmadrazatrust/',
    link5: 'https://www.youtube.com/imamahmadrazatrust',
    link6: 'https://www.facebook.com/ImamAhmadRazaTrust/',
    link7: "https://whatsapp.com/channel/0029VaAHqOv2ZjClsvLkGK2f",
    icon: IconAccessPoint,
    icon2: IconBrandTwitter,
    icon3: IconBrandInstagram,
    icon4: IconBrandYoutube,
    Icon5: IconBrandFacebook,
    Icon6: IconBrandWhatsapp,
  },
];

const clientId = process.env.REACT_APP_GOOGLE_CLIENTID as string;
const readyear = process.env.REACT_APP_READ_YEAR as string;

function AppRouter() {
  const IARTLogo = require("../Images/IARTNewLogo.png");
  const iartlogo = require("../Images/iartlogo.png")
  const location = useLocation();
  const userProfile = useAppSelector(
    (state: any) => state.userProfile.userData
  );
  const [userType] = useState(null);
  const [isLoading] = useState(false);
  const isAdminRoute = location.pathname.startsWith("/admin");
  const [iscloseModal, setclosemodal] = useState(true);
  const themeContext = useContext(ThemeContext);
  const isDarkMode = themeContext?.isDarkMode ?? false;
  const toggleTheme = themeContext?.toggleTheme ?? (() => { });
  const cookies = new Cookies();
  const [userLogedin, setUserLogedin] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const token = cookies.get("authToken");
  const [ModalOpen] = useState(true);
  const url: string = process.env.REACT_APP_API_URL!;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const closeModal = () => {
    setclosemodal(!iscloseModal);
    navigate("/home");
  };

  useEffect(() => {
    if (token) {
      setUserLogedin(true);
      const fetchProfile = async () => {
        try {
          const actualToken =
            typeof token === "object" && token.data ? token.data.token : token;

          const response = await getProfileApi(actualToken, url);
          setUserDetails(response);
          if (response) {
            dispatch(setUserProfile(response));
          }
        } catch (error) { }
      };

      fetchProfile();
    }
  }, [dispatch, token, url]);

  useEffect(() => {
    if (location.pathname === "/login") {
      if (userProfile) {
        setclosemodal(false);
      } else {
        setclosemodal(true);
      }
    }
  }, [location.pathname, userProfile]);
  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }

  const menugroups = [
    {
      label: "Departments",
      path: "/seealldepartments",
      onClick: () => navigate("/seealldepartments"),
    },
    {
      label: "Projects",
      path: "/ourprojects",
      onClick: () => navigate("/ourprojects"),
    },
    {
      label: "Gallery",
      path: "/gallery",
      onClick: () => navigate("/gallery"),
    },
  ];
  const donationHistory = {
    label: "Donation History",
    onClick: () => navigate("/donation-history"),
  };


  const images = [
    "../../Images/Homepage.jpg",
    "../../Images/Homepage2.jpg",

  ]

  return (
    <>
      {!isAdminRoute && (
        <Header
          toggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
          userLogedin={userLogedin}
          userType={userType}
          url={url}
          img={IARTLogo}
          name="IMAM AHMED RAZA TRUST"
          userdetails={userDetails}
          menugroups={menugroups}
          donationHistory={donationHistory}
        />
      )}
      
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home Registration={OnlineClass} departmentsData={departmentsData} WhyChooseus={WhyChooseus} mockdata={mockdata} isDarkMode={isDarkMode} partnerships={partnerships} bankaccounts={bankaccounts} projectData={projectData} images={images} />} />
        <Route path="/about" element={<About />} />
        <Route path="jrm/online/registration" element={<StudentRegistration />} />
        <Route
          path="/login"
          element={
            iscloseModal ? (
              <Login
                closeModal={closeModal}
                isDarkMode={isDarkMode}
                url={url}
                ModalOpen={ModalOpen}
                img={iartlogo}
              />
            ) : (
              null
            )
          }
        />
        <Route path="/contact" element={<ContactUs mockdata={mockdata} isDarkMode={isDarkMode} />} />
        <Route path="/seealldepartments" element={<SeeAllDepartments />} />
        <Route path="/jamaterazamustafa" element={<JamateRazaMustafa />} />
        <Route path="/sharaeecouncilindia" element={<SharaeeCouncilIndia />} />
        <Route path="/markazidarulifta" element={<MarakaziDarulIfta />} />
        <Route path="/sunniduniya" element={<MonthlySunniDuniya />} />
        <Route path="/jamiaturraza" element={<JamiaturRaza />} />
        <Route path="/ourprojects" element={<OurProjects />} />
        <Route path="/hamidimasjid" element={<HamidiMasjid />} />
        <Route path="/quadrihostel" element={<QuadriHostel />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/privacyandpolicy" element={<Privacy />} />
        <Route path="/refundPolicy" element={<RefundPolicy />} />


        {userProfile?.roleType === 2 ? (
          <Route path="/admin" element={<AdminSideBar />}>
            <Route path="donationtypepurpose" element={<DonationTypePurpose url={url} />} />
            <Route path="donationtypepurposeform" element={<DonationTypePurposeForm url={url} />} />
            <Route path="Expense" element={< Expense url={url } readyear="readyear" />} />
            <Route path="ExpenseForm" element={< ExpenseForm url={url} />} />
            <Route path="DonationDetails" element={< DonationDetails url={url} isDarkMode={isDarkMode} readyear={readyear} />} />
            <Route path="DonationDetailForm" element={< DonationDetailForm url={url} />} />
            <Route path="BankDetails" element={< BankDetails url={url} />} />
            <Route path="BankDetailsForm" element={< BankDetailsForm url={url} />} />
            <Route path="BalanceTransfer" element={< BalanceTransfer url={url} readyear="readyear" />} />
            <Route path="BalanceTransferForm" element={< BalanceTransferForm url={url} />} />
            <Route path="Box" element={< Box url={url} />} />
            <Route path="BoxForm" element={< BoxForm url={url} />} />
            <Route path="users" element={<Users url={url} />} />
            <Route path="users/userform" element={<UserForm url={url} />} />
            <Route path="taxinomies" element={<Taxionomies url={url} />} />
            <Route path="taxinomies/taxonomyform" element={<TaxonomyForm url={url} />} />
            <Route path="template" element={<Template url={url} />} />
            <Route path="template/templateform" element={<TemplateFrom url={url} />} />
            <Route path="Course" element={<Course url={url} />} />
            <Route path="CourseForm" element={<CourseForm url={url} />} />
            <Route path="CourseType" element={<CourseType url={url} />} />
            <Route path="CourseTypeForm" element={<CourseTypeForm url={url} />} />
            <Route path="CourseOffering" element={<CourseOffering url={url} />} />
            <Route path="CourseOfferingForm" element={<CourseOfferingForm url={url} />} />
            <Route path="EducationType" element={<EducationType url={url} />} />
            <Route path="EducationTypeForm" element={<EducationTypeForm url={url} />} />
            <Route path="Registration" element={<Registration url={url} />} />

          </Route>
        ) : null}
      </Routes>

      {!isAdminRoute && (
        <Footer
          isDarkMode={isDarkMode}
          companyName={"Eleven Software Solutions Pvt Ltd"}
          name="Imam Ahmed Raza Trust"
          img={IARTLogo}

        />
      )}
    </>
  );
}
function Router() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={clientId}>
        <AppRouter />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default Router;