import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";
import toast from "react-hot-toast";
import { useAppSelector } from "../../hooks/redux/redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUser, FaGraduationCap } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";

import {
    getCourseApi,
    getCourseTypeApi,
    getCourseOfferingApi,
    addCourseRegistrationApi,
} from "@11solutionspvtltd/eleven-solutions-trust-website";

import {
    updateUserApi,
} from "@11solutionspvtltd/eleven-solutions-trust-website";
import { updateUserProfile } from "../../hooks/redux/slices/userProfileSlice";
import { IconTrash } from "@tabler/icons-react";

type CourseTypeInterface = {
    id: string;
    name: string;
    parentId: string | null;
    durationType: string;
    fees: number;
    availability: any;
};

type CourseInterface = {
    id: string;
    name: string;
    gender: number | null
};

const StudentRegistration = () => {

    const url = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }
    var totalMinutes;
    const { isDarkMode } = themeContext;

    const [courseTypes, setCourseTypes] = useState<CourseTypeInterface[]>([]);
    const [courses, setCourses] = useState<CourseInterface[]>([]);
    const [selectedCourseType, setSelectedCourseType] = useState<string>("");
    const [selectedCourse, setSelectedCourse] = useState<string>("");

    const [timeZone, setTimeZone] = useState("");
    const [preferredTiming, setPreferredTiming] = useState("");

    const [duration, setDuration] = useState('30');
    const [customDuration, setCustomDuration] = useState('');
    useEffect(() => {
        if (duration !== 'custom' && customDuration) {
            setCustomDuration('');
        }
    },[duration]);

    const [Id, setId] = useState("");
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Gender, setGender] = useState("");
    const [Address, setAddress] = useState("");
    const [RoleType, setRoleType] = useState("");

    const steps = ["User Details", "Course Details", "Time"];
    const [currentStep, setCurrentStep] = useState(1);

    const userProfile = useAppSelector((state) => state.userProfile?.userData);

    useEffect(() => {
        setId(userProfile?.id || "");
        setEmail(userProfile?.email || "");
        setMobile(userProfile?.mobile || "");
        setName(userProfile?.name || "");
        setGender(userProfile?.gender === 1 ? "Male" : userProfile?.gender === 2 ? "Female" : "");
        setAddress(userProfile?.address || null);
        setRoleType(userProfile?.roleType.toString() || "");

    }, [userProfile]);

    // Fetch course types and courses
    const handleApiCallClick = async () => {
        try {
            const CourseOfferingData: any = await getCourseOfferingApi(url);
            const fetchedCourseTypes: any = await getCourseTypeApi(url);
            const fetchedCourses: any = await getCourseApi(url);

            const courseTypeIds = CourseOfferingData.map((detail: any) => detail.courseTypeId);

            const filteredCourseTypes = fetchedCourseTypes.filter(
                (item: CourseTypeInterface) => courseTypeIds.includes(item.id)
            );
            setCourseTypes(filteredCourseTypes);

            if (selectedCourseType) {
                const filteredCourses = fetchedCourses.filter((course: CourseInterface) => {
                    const isGenderMatch =
                        course.gender === null || // For "All"
                        (Gender === "Male" && course.gender === 1) || // Match Male
                        (Gender === "Female" && course.gender === 2); // Match Female

                    return CourseOfferingData.some(
                        (detail: any) =>
                            detail.courseId === course.id &&
                            detail.courseTypeId === selectedCourseType &&
                            isGenderMatch
                    );
                });

                setCourses(filteredCourses);
            }

        } catch (error) {
            console.error("Failed to fetch Course data. Some problem occurred. Please try again.");
        }
    };

    useEffect(() => {
        handleApiCallClick();
    },[selectedCourseType]);

    const handleSubmitClick = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!selectedCourse || !selectedCourseType) {
            alert("Please select both course type and course.");
            return;
        }

        try {
            const updatedGender = Gender === "Male" ? 1 : Gender === "Female" ? 2 : null;
            await updateUserApi.updateUserApi(url, Id, Name, Email, Mobile, Address, RoleType, updatedGender);
            const userData = {
                id: Id,
                name: Name,
                email: Email,
                mobile: Mobile,
                address: Address,
                roleType: RoleType,
                gender: updatedGender
            }

            await dispatch(updateUserProfile(userData));

            const CourseOfferingData: any = await getCourseOfferingApi(url);
            const selectedCourseOffering = CourseOfferingData.find(
                (detail: any) => detail.courseId === selectedCourse && detail.courseTypeId === selectedCourseType
            );
            if (!selectedCourseOffering) {
                alert("Could not find the course details for the selected course and type.");
                return;
            }
            const courseOfferingId = selectedCourseOffering.id;
            const timeZoneAbbreviation = timeZone.split(" ")[0];
            const Duration = parseFloat((parseInt(duration === "custom" ? customDuration : duration, 10) / 60).toFixed(2));

            await addCourseRegistrationApi.addCourseRegistrationApi(
                courseOfferingId,
                Duration,
                timeZoneAbbreviation,
                preferredTiming,
                url
            );
            setSelectedCourseType("");
            setSelectedCourse("");

            setCurrentStep(1);
            toast.success('Registered successfully');
            navigate("/home");
        } catch (error) {
            alert("An error occurred during course registration.");
            toast.error('An error occurred during course registration.');
            console.error(error);
        }

    };

    const handleCustomDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue)) {
            const roundedValue = Math.round(numericValue / 30) * 30;
            setCustomDuration(roundedValue.toString());
        } else {
            setCustomDuration('');
        }
    };

    const handleNextStep = () => {
        if (!Name || !Email || !Mobile || !Gender) {
            toast.error('Please fill in all required fields.');
            return; // Prevent moving to the next step
        }

        setCurrentStep((prev) => Math.min(prev + 1, steps.length));
    };

    const handlePrevStep = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 1));
    };

    const calculateOptions = () => {
        const availability = courseTypes.find(type => type.id === selectedCourseType)?.availability;
        const options = [];
        if (availability) {
            availability.forEach((timeslot) => {
                let start, end, totalHours;
                if (timeslot.availability === "24/7") {
                    start = 0;
                    end = 24;
                    totalHours = 24;
                } else {
                    [start, end] = timeslot.availability.split('-').map(time => {
                        const [hours, minutes] = time.split(':').map(Number);
                        return hours + minutes / 60; // Convert minutes to fractional hours
                    });
                    totalHours = end - start;
                }

                const durationInHours = parseInt(duration === "custom" ? customDuration : duration, 10) / 60;
                for (let i = 0; i <= totalHours - durationInHours; i += 0.5) {
                    const startTime = start + i;
                    const endTime = startTime + durationInHours;

                    const startHour = Math.floor(startTime);
                    const startMinutes = (startTime % 1) * 60;
                    const endHour = Math.floor(endTime);
                    const endMinutes = (endTime % 1) * 60;
                    options.push(
                        <option key={`${startHour}:${startMinutes}-${endHour}:${endMinutes}`} value={`${startHour}:${startMinutes.toString().padStart(2, '0')}-${endHour}:${endMinutes.toString().padStart(2, '0')}`}>
                            {`${startHour}:${startMinutes.toString().padStart(2, '0')} - ${endHour}:${endMinutes.toString().padStart(2, '0')}`}
                        </option>
                    );
                }
            });
        }
        return options;
    };

    useEffect(() => {
        calculateOptions();
    }, [duration, customDuration]);

    return (
        <div className={`w-full lg:px-[300px] overflow-auto  flex flex-col item-start py-4 
            ${isDarkMode
                ? "bg-globalBg border-b border-gray-700"
                : "bg-[#ffff] border-b border-gray-200"
            }`}>

            <div className="relative px-2  w-[100%]  flex flex-col items-center justify-center mb-8">
                <div className="relative flex items-center justify-between w-[95%] m-5 ">
                    <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 border bg-gray-300"></div>
                    <div
                        className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-blue-600 transition-all duration-500"
                        style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}

                    ></div>
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`relative z-10 grid w-10 h-10 font-bold text-white transition-all duration-300 ${index + 1 <= currentStep ? "bg-blue-700 text-grey-300" : "bg-gray-300 text-gray-900"
                                } rounded-full place-items-center`}
                        >
                            {step === "User Details" ? (
                                <FaUser />
                            ) : step === "Course Details" ? (
                                <FaGraduationCap
                                    className={`w-6 h-6 ${index + 1 <= currentStep ? "text-white" : "text-gray-900"}`}
                                />
                            ) : (
                                <MdAccessTimeFilled className={`w-6 h-6 ${index + 1 <= currentStep ? "text-white" : "text-gray-900"}`} />
                            )}


                            <div className="absolute -bottom-[2.5rem] w-max text-center ">
                                <h6 className={`block font-sans text-base antialiased font-semibold leading-relaxed tracking-normal  ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>
                                    {steps[index]}
                                </h6>

                            </div>
                        </div>
                    ))}
                </div>

                {/* Step Content */}
                <div className="lg:mt-[7%] sm:mt-[10%] mt-[30%] w-full">
                    {currentStep === 1 && (
                        <form>


                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Name</label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="name"
                                        placeholder="Enter your Full Name"
                                        value={Name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Email</label>
                                    <input
                                        type="email"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="email"
                                        placeholder="Enter your Email"
                                        value={Email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Mobile</label>
                                    <input
                                        type="text"
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        id="mobile"
                                        placeholder="Enter your Mobile Number"
                                        value={Mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Gender:</label>
                                    <select

                                        id="gender"
                                        value={Gender}
                                        onChange={(e) => setGender(e.target.value)}
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                        </form>

                    )}
                    {currentStep === 2 && (
                        <form>
                            <div className="flex flex-col space-y-4">
                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Course Type</label>
                                    <select
                                        required
                                        id="courseType"
                                        value={selectedCourseType}
                                        onChange={(e) => {
                                            setSelectedCourseType(e.target.value)
                                        }}
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select Course Type</option>
                                        {courseTypes.map((type) => (
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex flex-col space-y-2 w-[50%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>Course</label>
                                    <select
                                        required
                                        id="course"
                                        value={selectedCourse}
                                        onChange={(e) => setSelectedCourse(e.target.value)}
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select Course</option>
                                        {courses.map((course) => (
                                            <option key={course.id} value={course.id}>
                                                {course.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    )}
                    {currentStep === 3 && (
                        <form>
                            <div className="flex flex-col space-y-4 items-center">
                                <div className="flex flex-col space-y-2 w-[90%]">
                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>
                                        Duration (in minutes)
                                    </label>
                                    <select
                                        required
                                        id="duration"
                                        value={duration}
                                        onChange={(e) => {
                                            setDuration(e.target.value)
                                        }
                                        }
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"

                                    >
                                        {selectedCourseType && courseTypes.find(type => type.id === selectedCourseType)?.availability[0].availability !== "24/7" ? (
                                            (() => {
                                                // const options = [];
                                                // courseTypes.find(type => type.id === selectedCourseType)?.availability.map((timeslot) => {

                                                //     console.log("Availability:", timeslot);
                                                //     const [start, end] = timeslot.availability.split('-').map(time => {
                                                //         const [hours, minutes] = time.split(':').map(Number);
                                                //         return hours + minutes / 60; // Convert minutes to fractional hours
                                                //     });
                                                //     console.log("Start:", start, "End:", end);
                                                //     const totalHours = end - start;
                                                //     totalMinutes = totalHours * 60;
                                                //     console.log("Total Hours:", totalHours);

                                                //     for (let i = 1; i <= Math.min(totalHours * 2, 6); i++) {
                                                //         const minutes = i * 30;
                                                //         if (minutes > totalHours * 60) {
                                                //             break;
                                                //         }
                                                //         console.log("Minutes:", minutes);
                                                //         options.push(<option key={minutes} value={minutes}>{minutes} minutes ({(minutes / 60).toFixed(1)} hour{minutes > 60 ? 's' : ''})</option>);
                                                //     }
                                                //     if (totalHours > 3) {
                                                //         options.push(<option key="custom" value="custom">Custom</option>);
                                                //     }

                                                // });


                                                const availability = courseTypes.find(type => type.id === selectedCourseType)?.availability.reduce(
                                                    (max: any, current: any) => {
                                                        const [maxStart, maxEnd] = max.availability.split('-').map((time: string) => {
                                                            const [hours, minutes] = time.split(':').map(Number);
                                                            return hours + minutes / 60;
                                                        });
                                                        const [currentStart, currentEnd] = current.availability.split('-').map((time: string) => {
                                                            const [hours, minutes] = time.split(':').map(Number);
                                                            return hours + minutes / 60;
                                                        });
                                                        const maxDuration = maxEnd - maxStart;
                                                        const currentDuration = currentEnd - currentStart;
                                                        return currentDuration > maxDuration ? current : max;
                                                    }, courseTypes.find(type => type.id === selectedCourseType)?.availability[0]).availability;
                                              
                                                const [start, end] = availability.split('-').map((time: string) => {
                                                    const [hours, minutes] = time.split(':').map(Number);
                                                    return hours + minutes / 60; // Convert minutes to fractional hours
                                                });
                                                const totalHours = end - start;
                                                totalMinutes = totalHours * 60;
                                                const options = [];
                                                for (let i = 1; i <= Math.min(totalHours * 2, 6); i++) {
                                                    const minutes = i * 30;
                                                    if (minutes > totalHours * 60) {
                                                        break;
                                                    }
                                                    options.push(<option key={minutes} value={minutes}>{minutes} minutes ({(minutes / 60).toFixed(1)} hour{minutes > 60 ? 's' : ''})</option>);
                                                }
                                                if (totalHours > 3) {
                                                    options.push(<option key="custom" value="custom">Custom</option>);
                                                }
                                                return options;
                                            })()
                                        ) : (
                                            <>
                                                <option value="30">30 minutes</option>
                                                <option value="60">60 minutes (1 hour)</option>
                                                <option value="90">90 minutes (1.5 hours)</option>
                                                <option value="120">120 minutes (2 hours)</option>
                                                <option value="150">150 minutes (2.5 hours)</option>
                                                <option value="180">180 minutes (3 hours)</option>
                                                <option value="custom">Custom</option>
                                            </>
                                        )}
                                    </select>
                                    {duration === 'custom' && (
                                        <div className="mb-4">
                                            <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>
                                                Custom Duration (in minutes)
                                            </label>
                                            <input
                                                type="number"
                                                id="customDuration"
                                                value={customDuration}
                                                onChange={handleCustomDurationChange}
                                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                placeholder="Enter duration (multiple of 30)"
                                                min="30"
                                                max={totalMinutes}
                                                step="30"
                                                required={duration === 'custom'}
                                            />
                                            <p className="text-sm text-gray-600 mt-1">Duration will be rounded to the nearest 30 minutes</p>
                                        </div>
                                    )}
                                </div>
                                <div className="flex flex-col space-y-2 w-[90%]">
                                    <h1>Select Time Zone</h1>
                                    <ul>
                                        <select
                                            required
                                            id="timeZone"
                                            value={timeZone}
                                            onChange={(e) => setTimeZone(e.target.value)}
                                            className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >
                                            <option value="">Select Time Zone</option>
                                            <option value="IST - Indian Standard Time">IST - Indian Standard Time (UTC+5:30)</option>
                                            {/* {timeZones.map((zone, index) => (
                                                <option
                                                    key={index} value={zone.abbreviation}>{zone.abbreviation} - {zone.fullName} ({zone.offset})</option>
                                            ))} */}
                                        </select>

                                    </ul>
                                </div>

                                <div className="flex flex-col space-y-2 w-[90%]">

                                    <label className={`block text-sm font-medium text-black dark:text-gray-200 mb-2 ${isDarkMode ? "text-gray-100" : " text-gray-700"}`}>
                                        Preferred Timings
                                    </label>

                                    <select
                                        required
                                        id="preferredTimings"
                                        value={preferredTiming}
                                        onChange={(e) => setPreferredTiming(e.target.value)}
                                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="">Select Preferred Timing</option>
                                        {calculateOptions()}
                                    </select>
                                </div>

                            </div>
                        </form>
                    )}
                </div>
                {/* Navigation Buttons */}
                <div className="flex w-full justify-between mt-10">
                    <button
                        className="select-none rounded-lg bg-blue-500 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                        onClick={handlePrevStep}
                        disabled={currentStep === 1}
                    >
                        Prev
                    </button>


                    {currentStep < steps.length ? (
                        <button
                            type="button"
                            onClick={handleNextStep}
                            disabled={currentStep === steps.length}
                            className="text-white bg-blue-600 hover:bg-blue-700 rounded-lg px-4 py-2"
                        >
                            Next
                        </button>

                    ) : (
                        <button
                            type="button"
                            onClick={handleSubmitClick}
                            className="text-white bg-blue-600 rounded-lg px-4 py-2"
                        >

                            Submit
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default StudentRegistration;
