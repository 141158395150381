import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";

const JamiaturRaza = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;

    const JamiaturRaza = require("../../../Images/Centerofislamic.png");
    const listStyle: React.CSSProperties = {
        listStyleType: "number",
    };
    return (
        <div
            className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
                } text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
                <div className="flex items-center justify-center mb-4">
                    <img src={JamiaturRaza} alt="jamiaturraza" width={200} height={200}></img>
                </div>
                <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-3xl lg:text-2xl md:mb-5 font-bold">
                    WELCOME TO THE CENTRE OF ISLAMIC STUDIES, JAMIATUR RAZA
                </h2>
                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        At Jamiatur Raza, we believe that the education and schooling of the
                        new generation are paramount for the benefit of mankind and the
                        flourishing of Islamic followers. This noble mission has always been
                        a priority for esteemed leaders such as Huzoor Mufti-e-Azam Hind
                        and, later, Qazi-ul-Quzat Filhind Huzoor Tajush Shari'a Allama
                        Akhtar Raza Khan Azhari. Their vision was to establish a grand
                        religious institution that fosters profound learning and a
                        comprehensive understanding of the Holy Qur'an and Sunnah. Our goal
                        is to adapt Islamic ideology to the current scientific and
                        technological era, ensuring its relevance and applicability.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Our institution aims to instill the true Islamic spirit and the
                        characteristics of devoted preachers in the new generation while
                        equipping them with modern knowledge. This holistic approach ensures
                        that our students are well-prepared to guard against heretical
                        ideologies and materialistic views. With a logical and reasonable
                        mindset, they can present Islam as an ideal, evergreen way of life
                        that is beneficial to mankind
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        In the 20th century, the great Islamic revival led by Imam Ahmad
                        Raza Khan awakened the Muslim community and safeguarded Islam
                        against the era's various seductions. Continuing this legacy, Huzoor
                        Tajush Shari'a felt a strong urge to establish an effective
                        education system for Sunni Muslims, combining Islamic teachings with
                        essential modern knowledge.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        To fulfill this vision, the Centre of Islamic Studies, Jamiatur
                        Raza, was established on May 29, 2000. From its inception, our
                        institution has been dedicated to the following objectives:
                    </p>
                    <ul
                        style={listStyle}
                        className="text-gray-500 mt-2 space-y-4 text-justify"
                    >
                        <li>
                            Profound Islamic Learning: Providing a deep understanding and
                            interpretation of the Holy Qur'an and Sunnah.
                        </li>
                        <li>
                            Integration with Modern Knowledge: Incorporating essential modern
                            branches of knowledge into the curriculum.
                        </li>
                        <li>
                            Islamic Spirit and Preaching: Instilling the true Islamic spirit
                            and characteristics of devoted preachers in our students.
                        </li>
                        <li>
                            Protection Against Heretical Ideologies: Safeguarding students
                            against heretical ideologies and materialistic views
                        </li>
                    </ul>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Enabling students to present Islam logically and reasonably as an
                        ideal way of life beneficial to mankind.
                    </p>
                    <p>
                        Through these aims and objectives, Jamiatur Raza strives to create a
                        generation of well-rounded, knowledgeable, and devout Muslims who
                        can navigate and contribute positively to the modern world while
                        upholding and spreading Islamic values.
                    </p>

                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Discover more about our programs and initiatives at
                    </p>
                    <a
                        href="www.cisjamiaturraza.ac.in.
"
                    >
                        www.cisjamiaturraza.ac.in.
                    </a>
                    <h3 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-3xl lg:text-2xl md:mb-5 font-bold text-center">
                        OUR VISION
                    </h3>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        At the Centre of Islamic Studies, Jamiatur Raza, our vision for the
                        next five years is both ambitious and transformative, reflecting our
                        commitment to advancing both academic and non-academic dimensions of
                        our institution. Our vision is divided into two key areas: Academic
                        and Non-Academic. Each segment is designed to enhance our
                        educational offerings and expand our impact on the Muslim community.
                    </p>
                    <div className="text-gray-500 mt-2 space-y-4 text-justify">
                        <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-2xl lg:text-xl md:mb-5 font-bold">
                            Academic Vision
                        </h2>
                        <ol
                            style={listStyle}
                            className="text-gray-500 mt-2 space-y-4 text-justify"
                        >
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>
                                    Establishing an Arabic University with Ideal Features
                                </strong>
                                <ul>
                                    <li>
                                        <strong>Objective:</strong> Evolve into a distinguished
                                        Arabic University, renowned for excellence in Islamic
                                        education and modern academic standards. Aspire to blend
                                        traditional Islamic scholarship with contemporary academic
                                        practices.
                                    </li>
                                    <li>
                                        <strong>Approach:</strong> Develop a comprehensive
                                        curriculum, state-of-the-art facilities, and a faculty of
                                        distinguished scholars to support the goal of becoming an
                                        ideal Arabic University.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>
                                    Launching Specialized Courses for the Indian Muslim Community
                                </strong>
                                <ul>
                                    <li>
                                        <strong>Objective:</strong> Address the educational needs of
                                        Indian Muslims, especially professionals like doctors, MCAs,
                                        and MBAs who may lack deep Islamic knowledge. Introduce
                                        tailored courses designed to provide a robust understanding
                                        of Islam.
                                    </li>
                                    <li>
                                        <strong>Program Design:</strong> Develop courses with the
                                        specific needs of professionals in mind, integrating Islamic
                                        teachings with practical knowledge to enhance their
                                        spiritual and professional lives.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Affiliation Program for Madrasa and Jamia</strong>
                                <ul>
                                    <li>
                                        <strong>Objective:</strong> Establish an affiliation
                                        department to provide accreditation and support to smaller
                                        madrasas and Jamias that may not have a comprehensive
                                        syllabus.
                                    </li>
                                    <li>
                                        <strong>Implementation:</strong> Standardize the curriculum
                                        across affiliated institutions, encouraging a unified
                                        approach to Islamic education and ensuring quality education
                                        for students in various regions.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Increasing Student Enrolment</strong>
                                <ul>
                                    <li>
                                        <strong>Goal:</strong> Increase student enrolment to 2,000
                                        students over the next five years. Currently, there are more
                                        than 1,000 students, with a commitment to significantly
                                        expanding the student body.
                                    </li>
                                    <li>
                                        <strong>Strategy:</strong> Enhance outreach efforts, improve
                                        academic offerings, and promote the unique strengths of the
                                        institution.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Developing Specialized Student Programs</strong>
                                <ul>
                                    <li>
                                        <strong>Fazilat Degree:</strong> Shape a team of 100
                                        students to earn the Fazilat degree, reflecting advanced
                                        Islamic scholarship and leadership.
                                    </li>
                                    <li>
                                        <strong>Hifz-e-Quran Pak Degree:</strong> Support 100
                                        students in achieving the Hifz-e-Quran Pak degree,
                                        emphasizing the memorization and recitation of the Holy
                                        Qur'an.
                                    </li>
                                    <li>
                                        <strong>Qiraat wa Tajveed:</strong> Train 25 students in
                                        Qiraat wa Tajveed, specializing in the correct pronunciation
                                        and recitation of the Qur'an.
                                    </li>
                                    <li>
                                        <strong>
                                            Takhas-sus fil Fiqh (Specialization in Fiqh):
                                        </strong>{" "}
                                        Enroll 25 students in the Takhas-sus fil Fiqh program,
                                        focusing on specialized Islamic jurisprudence.
                                    </li>
                                </ul>
                            </li>
                        </ol>

                        <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-2xl lg:text-xl md:mb-5 font-bold">
                            Non-Academic Vision
                        </h2>
                        <ul className="text-gray-500 mt-2 space-y-4 text-justify">
                            <li>
                                <strong>Community Engagement:</strong> Enhance engagement with
                                the local and global Muslim community through various outreach
                                programs and community services.
                            </li>
                            <li>
                                <strong>Infrastructure Development:</strong> Invest in the
                                development of campus infrastructure, including new facilities,
                                technological upgrades, and resource enhancements.
                            </li>
                            <li>
                                <strong>Student Support Services:</strong> Improve and expand
                                support services for students, including counseling, career
                                guidance, and extracurricular activities to foster holistic
                                development.
                            </li>
                        </ul>

                        <p className="text-sm sm:text-base md:text-lg lg:text-base">
                            By implementing these strategic plans, we are dedicated to
                            advancing our institution's role as a beacon of Islamic education
                            and contributing positively to the broader community. We are
                            confident that, with the grace of Allah, these goals will be
                            realized and will significantly enhance the quality and scope of
                            education at Jamiatur Raza.
                        </p>
                    </div>

                    <div className="text-gray-500 mt-2 space-y-4 text-justify">
                        <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-2xl lg:text-xl md:mb-5 font-bold text-center">REQUIREMENTS FOR THE NEXT FIVE YEARS</h2>
                        <p>
                            To support our ambitious plans and ensure the continued growth and
                            success of the Centre of Islamic Studies, Jamiatur Raza, we have
                            identified several key infrastructure requirements. These
                            developments are essential to realizing our vision and providing
                            an enhanced environment for both students and staff.
                        </p>
                        <p className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-2xl lg:text-xl md:mb-5 font-semibold">
                            Below is a detailed outline of the buildings and facilities
                            required for our future plans:
                        </p>

                        <ol  style={listStyle}>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Building for Administrative Office</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> This facility will centralize all
                                        administrative functions, streamlining operations and
                                        improving efficiency in managing the institution's affairs.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> The building will house offices
                                        for administrative staff, meeting rooms, and support
                                        services to ensure smooth and effective management.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Building for Darul Qaza & Darul Ifta</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> Dedicated spaces for Darul Qaza
                                        (Islamic court) and Darul Ifta (Islamic jurisprudence
                                        office) will provide essential services related to Islamic
                                        legal rulings and fatwas.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> This building will include
                                        offices and consultation rooms for scholars and clerks
                                        involved in issuing legal opinions and handling religious
                                        disputes.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Building for Darul Tasneef wa Taaleef</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> To support the compilation and
                                        publication of Islamic texts, this building will focus on
                                        the production and dissemination of scholarly works.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> The facility will include
                                        research rooms, editing suites, and publishing offices to
                                        facilitate the creation and distribution of Islamic
                                        literature.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Building for Central Library</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> A new, dedicated Central Library
                                        will enhance our research and academic resources, providing
                                        a comprehensive collection of books, manuscripts, and
                                        digital materials.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> The library will offer study
                                        areas, archival storage, and research facilities, designed
                                        to support extensive academic research and learning.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Academic Building for Extension of Classrooms</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> To accommodate the growing number
                                        of students and expand our educational programs, we will
                                        build additional classroom space.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> This building will include modern
                                        classrooms, seminar rooms, and facilities to support a
                                        dynamic and interactive learning environment.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Separate Building for Computer Lab</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> A dedicated Computer Lab will
                                        provide students with access to technology and resources
                                        essential for modern education.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> The lab will be equipped with
                                        computers, software, and high-speed internet, offering
                                        students the tools needed for research, projects, and
                                        learning.
                                    </li>
                                </ul>
                            </li>
                            <li className="text-gray-500 mt-2 space-y-4 text-justify">
                                <strong>Commercial Complex</strong>
                                <ul>
                                    <li>
                                        <strong>Purpose:</strong> The construction of a commercial
                                        complex will support the institution's financial
                                        sustainability and provide various services and retail
                                        options.
                                    </li>
                                    <li>
                                        <strong>Features:</strong> The complex will include
                                        commercial spaces for retail, services, and other business
                                        activities, contributing to the institution's revenue and
                                        providing conveniences for students and staff.
                                    </li>
                                </ul>
                            </li>
                        </ol>

                        <p>
                            These developments are crucial for advancing our institution's
                            capabilities and ensuring we can provide top-notch educational and
                            administrative services. By implementing these projects, we aim to
                            enhance our facilities, support our growing student body, and
                            further our mission of delivering high-quality Islamic and modern
                            education.
                        </p>
                    </div>

                    <p className="text-sm sm:text-base md:text-lg lg:text-base"></p>
                    <p className="class"></p>
                </div>
            </div>
        </div>
    );
};

export default JamiaturRaza;
