
import React, { useContext } from 'react'
import { ThemeContext } from './theme/ThemeProvider';

const Privacy = () => {
    const themeContext = useContext(ThemeContext);

    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;

    return (
        <div className={`min-h-[70vh] p-4 text-justify lg:px-72 lg:text-justify ${isDarkMode ? "bg-globalBg text-[#ffff]" : "bg-white text-black"}`}>
            <h2 className="text-xl font-extrabold mt-4 text-blue-400 text-center">PRIVACY AND POLICY</h2>
            <p className="text-sm text-gray-500 mt-3">
                Welcome to Imam Ahmed Raza Trust - Islamic Trust For Donation. This Privacy Policy is
                designed to help you understand how we collect, use, disclose, and safeguard your personal
                information when you use our website, services, or interact with us.
            </p>

            <h5 className="text-lg font-medium mt-4">1. Information We Collect</h5>
            <p className="text-sm text-gray-500 mt-2">
                <strong>Personal Information: </strong>We may collect personal information that you
                provide to us voluntarily, such as your name, contact information, and donation details when
                you use our website or engage with our services.
            </p>
            <p className="text-sm text-gray-500 mt-2">
                <strong>Non-Personal Information: </strong>We may also collect non-personal information,
                such as aggregated data, browser type, and IP address, for analytical and statistical
                purposes.
            </p>

            <h5 className="text-lg font-medium mt-4">2. How We Use Your Information</h5>
            <p className="text-sm text-gray-500 mt-2">
                <strong>Donation Processing: </strong>We use your personal information to process and
                manage your donations, provide receipts, and communicate with you regarding your
                contributions.
            </p>
            <p className="text-sm text-gray-500 mt-2">
                <strong>Communication: </strong>We may use your contact information to send you updates,
                newsletters, or information about our initiatives, events, and fundraising activities.
            </p>
            <p className="text-sm text-gray-500 mt-2">
                <strong>Analytics: </strong>Non-personal information may be used for analytics and to
                improve our website functionality, content, and user experience.
            </p>

            <h5 className="text-lg font-medium mt-4">3. Information Sharing</h5>
            <p className="text-sm text-gray-500 mt-2">
                We do not sell, trade, or rent your personal information to third parties. Your information
                may be shared with trusted third parties, such as payment processors, solely for the purpose
                of processing your donations.
            </p>

            <h5 className="text-lg font-medium mt-4">4. Security</h5>
            <p className="text-sm text-gray-500 mt-2">
                We are committed to protecting the security of your personal information. We implement
                appropriate technical and organizational measures to safeguard against unauthorized access,
                disclosure, alteration, and destruction of data.
            </p>

            <h5 className="text-lg font-medium mt-4">5. Cookies</h5>
            <p className="text-sm text-gray-500 mt-2">
                Our website may use cookies to enhance your browsing experience. You can manage cookie
                preferences through your browser settings.
            </p>

            <h5 className="text-lg font-medium mt-4">6. Your Choices</h5>
            <p className="text-sm text-gray-500 mt-2">
                You have the right to update, correct, or delete your personal information. You can also
                opt-out of receiving communications from us.
            </p>

            <h5 className="text-lg font-medium mt-4">7. Changes to this Privacy Policy</h5>
            <p className="text-sm text-gray-500 mt-2">
                We reserve the right to update this Privacy Policy. Any changes will be effective upon
                posting the revised policy on our website.
            </p>

            <h5 className="text-lg font-medium mt-4">8. Contact Us</h5>
            <p className="text-sm text-gray-500 mb-4">
                If you have any questions or concerns about this Privacy Policy, please contact us.
            </p>
        </div>
    )
}

export default Privacy