import { createSlice } from "@reduxjs/toolkit";
interface ProfileData {
  id: string;
  name: string | null;
  mobile: string | null;
  email: string;
  address: string | null;
  gender : number | null;
  roleType: number;
}

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    userData: null as ProfileData | null,
    isLoggedIn: false,
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.userData = action.payload;
      state.isLoggedIn = true;
    },
    clearUserProfile: (state) => {
      state.userData = null;
      state.isLoggedIn = false;
    },
    updateUserProfile: (state, action) => {
      
      if (state.userData) {
        state.userData = { ...state.userData, ...action.payload };
      }
    }
  },
});

export const { setUserProfile, clearUserProfile, updateUserProfile } = userProfileSlice.actions;
export default userProfileSlice.reducer;
