import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";

const MonthlySunniDuniya = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;
    const monthlySunniDuniya = require("../../../Images/Monthlysunniduniya.png");
    return (
        <div
            className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
                } text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
                <div className="flex items-center justify-center mb-4">
                    <img src={monthlySunniDuniya} alt="monthlysunniduniya" width={200} height={200}></img>
                </div>
                <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-3xl lg:text-2xl md:mb-5 font-bold">
                    MONTHLY SUNNI DUNIYA
                </h2>
                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        It is vital for the growth and expansion of any organization or
                        movement that it has its own managed and organized media. Without
                        which, the duties of propagation cannot be performed effectively.
                        Otherwise, the message of any movement or an organization wouldn't
                        be long-lasting and consistent. The Great theologian and Leader,
                        AlaHazrat Ash-Shah Imam Ahmad Raza Khan (Alayhir Rahmah) also
                        focused on its importance in his renowned 10-points agenda, He says:
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        "Daily or weekly newspapers and magazines having articles in the
                        favour of the religion should be published and distributed around
                        the country." In the present day and age, while the opponents are
                        trying to defame Islam themselves and on the other hand, they are
                        funding the sell-outs who are trying their best to portray the wrong
                        image of Islam, it becomes increasingly necessary to publicize the
                        true teachings of Islam in addition to the pursuit of the opponents,
                        it equally important to track down and expose their slaves as well.
                        Realizing this obligation, His Excellency, the great Gnostic, Jurist
                        of Islam, Islamic Chief Justice of India, Taaj al-Shari'ah Allamah
                        Mufti Muhammad Akhtar Raza Khan Qadiri Baraylawi (Alayhir Rahmah)
                        initiated "Monthly Sunni Duniya" Magazine in the year 1982, which is
                        very vigilantly carrying out its duties and truly representing the
                        Ahl as-Sunnah wa al-Jama'ah. There are very few magazines who have
                        continued their journey in the field of honest journalism for such a
                        long period.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Under the active supervision of Mufti Muhammad Asjad Raza Khan
                        Qadiri Baraylawi (may Allah preserve him), the monthly Sunni Duniya
                        magazine is gaining momentum by the grace of Allah Almighty
                        fulfilling the modern day requirements of journalism, printing and
                        publishing. So please take part in the progress of this Islamic
                        monthly magazine.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MonthlySunniDuniya;
