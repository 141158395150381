import React from "react";
import RefundPolicydata from "../Json Data/Refundpolicy";
import { useContext } from "react";
import { ThemeContext } from "./theme/ThemeProvider";

const RefundPolicy = () => {
    const themeContext = useContext(ThemeContext);

    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;

    return (
        <div
            className={`md:mx-auto min-h-[70vh] p-4 text-justify lg:px-72 lg:text-justify ${isDarkMode ? "bg-globalBg text-[#ffff]" : "bg-white text-black"
                }`}
        >
            <h2
                className={`font-extrabold mb-4 text-2xl lg:text-center md:text-center ${isDarkMode ? "text-blue-500" : "text-blue-400"
                    }`}
            >
                {RefundPolicydata.refundPolicy.title}
            </h2>
            <p className="text-sm mt-4">{RefundPolicydata.refundPolicy.introText}</p>
            {RefundPolicydata.refundPolicy.sections.map((section, index) => (
                <div key={index} className="mt-6">
                    <h5
                        className={`text-base font-semibold ${isDarkMode ? "text-yellow-300" : "text-black"
                            }`}
                    >
                        {section.title}
                    </h5>
                    {Array.isArray(section.content) ? (
                        section.content.map((item, idx) => (
                            <div key={idx} className="mt-2">
                                {typeof item === "string" ? (
                                    <p className="text-sm">{item}</p>
                                ) : (
                                    <ul className="list-disc ml-5 text-sm">
                                        {item.listItems.map((listItem, listIdx) => (
                                            <li key={listIdx}>{listItem}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className="text-sm mt-2">{section.content}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default RefundPolicy;

