import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";

const SharaeeCouncilIndia = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;
    const shareecouncilofindia = require("../../../Images/Shareecouncil.png");
    return (
        <div
            className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
                } text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
                <div className="flex items-center justify-center mb-4">
                    <img src={shareecouncilofindia} alt="shareecouncilindia" width={200} height={200}></img>
                </div>
                <h2 className="font-greycliff-cf text-blue-500 mt-0 text-3xl md:text-4xl lg:text-3xl md:mb-5 font-bold">
                    Shara'ee Council of India
                </h2>
                <h4 className="font-greycliff-cf text-blue-500 mt-0 text-3xl md:text-2xl lg:text-xl md:mb-5 font-semibold">
                    In the name of Allah, the Most Gracious, the Most Merciful
                </h4>
                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Since the latter half of the 13th century, the Markaz-e Ahle Sunnat
                        in Bareilly Shareef has been a central and revered institution for
                        the Ahle Sunnat Wa Jama'at community. People from all over the
                        country have turned to this esteemed center for guidance on
                        religious and legal issues. By the grace of Allah, this tradition
                        has continued to thrive to this day.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Prominent figures such as Maulana Raza Ali Khan, Rais
                        al-Mutakallimeen Allama Naqi Ali Khan, Imam Ahmed Raza Khan, Hujjat
                        al-Islam Maulana Hamid Raza Khan, Mufti Azam Hind Maulana Mustafa
                        Raza Khan, Mufassir Azam Hind Maulana Ibrahim Raza Khan, and
                        Tajushshariah Allama Mufti Akhtar Raza Khan (may Allah sanctify
                        their secrets) have all played a significant role in addressing
                        complex issues faced by the Ummah and providing guidance on the
                        right path.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Among them, the latter figure, Allama Mufti Akhtar Raza Khan,
                        dedicated a substantial portion of his life to this noble endeavour.
                        When his health began to decline and the task became increasingly
                        demanding, he responded to the request of some friends and, with the
                        counsel of scholars and elders, established an organization named
                        "Shar'ee Council of India", Bareilly Shareef on 7th Jumada al-
                        Akhirah 1424H, corresponding to 8th August 2003. The organization
                        was under the direct supervision of Tajushshariah Allama Mufti
                        Akhtar Raza Khan (may Allah have mercy on him), and the
                        administrative responsibilities were entrusted to his son Allama
                        Mufti Asjad Raza Khan Qadri (may Allah protect him), with the aim of
                        providing solutions to unresolved issues through mutual consensus
                        among scholars and Muftis.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Following the establishment of the organization, the first legal
                        seminar was held on 16th and 17th Rajab al-Murajjab 1425H,
                        corresponding to 2nd and 3rd September 2004. This seminar aimed to
                        find consensus solutions to newly arising issues and was attended by
                        distinguished scholars and religious leaders from across the
                        country. The seminar addressed complex legal matters and proposed
                        solutions after thorough discussion. Since then, annual legal
                        seminars have continued to be held at the Center of Islamic Jamiatur
                        Raza, and in various
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        Following the establishment of the organization, the first legal
                        seminar was held on 16th and 17th Rajab al-Murajjab 1425H,
                        corresponding to 2nd and 3rd September 2004. This seminar aimed to
                        find consensus solutions to newly arising issues and was attended by
                        distinguished scholars and religious leaders from across the
                        country. The seminar addressed complex legal matters and proposed
                        solutions after thorough discussion. Since then, annual legal
                        seminars have continued to be held at the Center of Islamic Jamiatur
                        Raza, and in various cities across the country. To date, several
                        successful legal seminars have been conducted by the Shar'ee Council
                        of India, Bareilly Shareef, guiding the Muslim Ummah.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The issues and responses from eleven of these seminars have been
                        published in a collection titled "Faisalajaat Shar'ee Council of
                        India" The remaining seminar issues and responses are also expected
                        to be published soon.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        In this era of technology, efforts are being made globally to
                        simplify various matters, including educational opportunities, using
                        technological advancements. The experts at Center of Islamic Studies
                        Jamiatur Raza, Bareilly Shareef, have embraced this trend. They have
                        made valuable materials, including issues and solutions from the
                        Shar'ee Council of India's seminars,Islamic Jamiatur Raza, and in
                        various cities across the country. To date, several successful legal
                        seminars have been conducted by the Shar'ee Council of India,
                        Bareilly Shareef, guiding the Muslim Ummah.
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        May Allah accept this noble effort made with sincere intentions and
                        make it increasingly beneficial for the Muslim Ummah. Ameen. By the
                        grace of the beloved Prophet )ﷺ(, the best of prayers and the most
                        esteemed greetings.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SharaeeCouncilIndia;
