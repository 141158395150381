import React, { useContext } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import imamahmedrazatrustlogo from "../../Images/IARTNewLogo.png"


function About() {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;

  return (
    <div
      className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"
        } text-justify flex flex-col justify-center items-center py-10`}
    >
      <div className="max-w-[980px] text-center px-4 mx-auto mb-2">
        <h2 className="font-greycliff-cf text-blue-500 mt-0 text-3xl md:text-4xl lg:text-3xl md:mb-5 font-bold">
          About Us
        </h2>
        <div className="flex items-center justify-center mb-4">
          <img src={imamahmedrazatrustlogo} alt="iartlogo" width={200} height={200}  ></img>
        </div>
        <div className="text-gray-500 mt-2 space-y-4 text-justify">
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            Huzoor Mufti-e-Azam-e-Hind (Rahimahullahu Ta'ala) envisioned imparting distinctive Deeni education to Muslims,
            enabling them to excel in this world and the hereafter. Qazi-ul-Quzzat Fil-Hind Huzoor
            Tajush Shari'ah Allama Mufti Muhammad Akhtar Raza Khan Qadri Azhari (Rahimahullahu Ta'ala)
            fulfilled this vision by establishing significant religious institutions such as Markazi Darul Ifta,
            Monthly Sunni Duniya, Jamiatur Raza, and Shar'ee Council of India.
            These institutions draw teachings from the Holy Qur'an, Sunnah, and the lives of Buzurgan-e-Deen,
            serving as profound learning centers that integrate Islamic ideology with modern science and technology.
            They aim to inculcate Islamic devotion in the youth while providing modern education to protect against heretical ideologies and materialism
          </p>
          <p className="text-sm sm:text-base md:text-lg lg:text-base">
            To support and strengthen these institutions, the Imam Ahmad Raza Trust was established on 10th January 1998 and registered on 10th January 2000,
            with Hazrat Allama Mufti Muhammad Asjad Raza Khan Hafidahullah as the Managing Trustee.
            The Trust aims for the social, economic, educational, cultural, and spiritual upliftment of the needy,
            especially Muslims, while combating social evils. It provides various educational facilities, such as financial assistance,
            scholarships, and free boarding, ensuring education for all regardless of financial background. Additionally,
            the Trust aids the poor and needy through hospitals, orphanages, and other channels,
            and offers support during natural calamities and disasters, reinforcing its commitment to societal well-being.
          </p>

        </div>
      </div>
    </div>
  );
}

export default About;

