import React, { useContext } from "react";
import { ThemeContext } from "../../theme/ThemeProvider";
import imamahmedrazatrustlogo from "../../../Images/IARTNewLogo.png";

const QuadriHostel = () => {
    const themeContext = useContext(ThemeContext);
    if (!themeContext) {
        throw new Error("ThemeContext must be used within a ThemeProvider");
    }

    const { isDarkMode } = themeContext;
    return (
        <div
        className={`${isDarkMode ? "bg-globalBg text-white" : "bg-[#FFFF]"} mx-auto text-justify flex flex-col justify-center items-center py-10`}
        >
            <div className="container text-center px-4 mx-[400px] mb-2">


                <div className="flex items-center justify-center mb-4">
                    <img src={imamahmedrazatrustlogo} alt="iartlogo" width={200} height={200} />
                </div>

                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <h1 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-3xl lg:text-2xl md:mb-5 font-bold text-center uppercase">
                        Projects of Imam Ahmad Raza Trust
                    </h1>
                    <h2 className="font-greycliff-cf text-blue-500 mt-0 text-xl md:text-2xl lg:text-xl md:mb-5 font-bold">
                        An Overview
                    </h2>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base text-left">
                        The Imam Ahmad Raza Trust is dedicated to advancing the educational
                        and spiritual environment of our community through continuous
                        development and infrastructure improvement. Presently, two major
                        projects are in progress: the construction of the Qadri Hostel and the
                        Hamidi Masjid. Additionally, we are undertaking a comprehensive
                        repainting of all existing buildings to rejuvenate and preserve our
                        facilities.
                    </p>
                    <div className="flex items-center justify-center ">
                        <img src="/Images/Qadrihostel.jpg" alt="Quadri hostel" className="w-2/3 md:h-2/4 lg:w-2/5 rounded-md mt-2 mb-3" />
                    </div>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        <strong className="text-left">Under Management:</strong>{" "}
                        Janashine-e-Tajushshariah Allama Mufti Asjad Raza Khan, Nazim Amityur
                        Raza
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        <strong>Project Name:</strong> New Hostel Block, Jamitur Raza,
                        Bareilly
                    </p>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        <strong>Designed By:</strong> Burhan Ali
                    </p>
                </div>

                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <h2 className="font-greycliff-cf text-blue-500 mt-0 text-lg md:text-xl lg:text-xl md:mb-5 font-semibold text-center">
                        Qadri Hostel: A Modern Residential Facility
                    </h2>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The Qadri Hostel is a landmark project that reflects our commitment to
                        providing students with a high-quality living experience. Designed as
                        a 5-story building with 100 rooms, this hostel will feature modern
                        facilities aimed at ensuring the comfort and well-being of its
                        residents.
                    </p>
                    <ul className="text-gray-500 mt-2 space-y-4 text-justify">
                        <li>
                            <strong>Building Structure and Design:</strong> The architectural
                            design of the Qadri Hostel blends modernity with practicality. The
                            building will be equipped with spacious rooms and well-ventilated
                            common areas, catering to the academic and personal needs of the
                            students.
                        </li>
                        <li>
                            <strong>Facilities:</strong> Each floor will include amenities such
                            as study rooms and common lounges. The hostel will also feature 24/7
                            security, ensuring a safe living environment. The ground floor will
                            house administrative offices and a reception area, making it easy
                            for students to access essential services.
                        </li>
                        <li>
                            <strong>Environmental Considerations:</strong> In line with our
                            commitment to sustainability, the hostel will incorporate
                            energy-efficient lighting, water-saving fixtures, and a waste
                            management system. The design also includes green spaces and a
                            rooftop garden for relaxation and reflection.
                        </li>
                        <li>
                            <strong>Construction Progress:</strong> The structural framework is
                            nearing completion. Interior work, including electrical
                            installations and plumbing, is currently underway. Once completed,
                            the Qadri Hostel will stand as a premier residential facility.
                        </li>
                    </ul>
                </div>

                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <h2 className="font-greycliff-cf text-blue-500 mt-0 text-lg md:text-xl lg:text-xl md:mb-5 font-semibold text-center">
                        Hamidi Masjid: A Spiritual Hub
                    </h2>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        The Hamidi Masjid is another significant project under the Imam Ahmad
                        Raza Trust, aimed at serving as a spiritual and communal hub for the
                        local and student communities.
                    </p>
                    <ul className="text-gray-500 mt-2 space-y-4 text-justify">
                        <li>
                            <strong>Architectural Significance:</strong> The masjid is being
                            constructed with an emphasis on both traditional Islamic
                            architecture and contemporary design elements. It features a large
                            prayer hall adorned with intricate calligraphy and art.
                        </li>
                        <li>
                            <strong>Interior Work:</strong> The interior work includes
                            installing elegant chandeliers, custom-designed carpets, and
                            advanced sound systems for immersive prayer experiences.
                        </li>
                        <li>
                            <strong>Additional Facilities:</strong> The masjid will include
                            spaces for Quranic studies, religious discussions, and separate
                            areas for ablution and Masjid administration offices.
                        </li>
                        <li>
                            <strong>Completion Timeline:</strong> The construction team is
                            diligently working to open the masjid to the community soon. It will
                            serve as both a place of worship and a center for learning.
                        </li>
                    </ul>
                </div>

                <div className="text-gray-500 mt-2 space-y-4 text-justify">
                    <h2 className="font-greycliff-cf text-blue-500 mt-0 text-lg md:text-xl lg:text-xl md:mb-5 font-semibold text-center">
                        Comprehensive Building Repainting: Preserving Our Legacy
                    </h2>
                    <p className="text-sm sm:text-base md:text-lg lg:text-base">
                        In addition to new constructions, the Imam Ahmad Raza Trust is also
                        focused on the maintenance and preservation of existing buildings. A
                        comprehensive repainting project is currently underway, covering all
                        buildings within our complex.
                    </p>
                    <ul className="text-gray-500 mt-2 space-y-4 text-justify">
                        <li>
                            <strong>Purpose of Repainting:</strong> This initiative helps
                            maintain the structural integrity and aesthetic appeal of our
                            buildings, protecting them from damage while revitalizing their
                            appearance.
                        </li>
                        <li>
                            <strong>Scope of Work:</strong> The repainting project includes
                            academic buildings, hostels, administrative offices, and other
                            facilities. Durable and weather-resistant paint is being used.
                        </li>
                        <li>
                            <strong>Aesthetic Enhancement:</strong> The color schemes reflect
                            the cultural and spiritual heritage of our institution while
                            blending harmoniously with the environment.
                        </li>
                    </ul>
                </div>

                <h2 className="font-greycliff-cf text-blue-500 mt-4 text-lg md:text-xl lg:text-2xl md:mb-5 font-semibold">
                    Conclusion
                </h2>
                <p className="text-gray-500 text-sm sm:text-base md:text-lg lg:text-base">
                    The Imam Ahmad Raza Trust remains steadfast in its mission to provide an
                    exemplary environment for education and spiritual growth. The ongoing
                    construction of the Qadri Hostel and Hamidi Masjid, alongside the
                    repainting of all buildings, demonstrates our commitment to continuous
                    improvement. These projects aim to create spaces where knowledge, faith,
                    and community can flourish together.
                </p>
            </div>
        </div>
    );
};

export default QuadriHostel;
