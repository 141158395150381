

const RefundPolicydata = {
  "refundPolicy": {
    "title": "Refund Policy",
    "introText": "Welcome to Imam Ahmed Raza Trust For Donation. This Refund Policy outlines the terms and conditions governing refunds for donations made to our organization.",
    "sections": [
      {
        "title": "1. Donations",
        "content": "All donations made to Imam Ahmed Raza Trust are considered final. We appreciate your generosity and commitment to supporting our mission to 'A step towards digitalization for Islamic Learning.'"
      },
      {
        "title": "2. Exceptional Circumstances",
        "content": "In exceptional circumstances, Imam Ahmed Raza Trust may consider refund requests on a case-by-case basis. Such circumstances may include: Technical Issues: If a donation was made in error due to technical issues with our website or payment processing system. Unauthorized Transactions: If a donation was made without the donor's consent or authorization."
      },
      {
        "title": "3. Refund Request Process",
        "content": [
          "If you believe you have a valid reason for requesting a refund, please contact Imam Ahmed Raza Trust within 7 days of the donation date.",
          "When submitting a refund request, please provide the following information:",
          {
            "listItems": [
              "Your Full Name",
              "Contact Information",
              "Donation Amount",
              "Date of the Donation",
              "Reason for the Refund Request"
            ]
          }
        ]
      },
      {
        "title": "4. Decision and Processing",
        "content": "Imam Ahmed Raza Trust will review each refund request individually. Our decision will be based on the circumstances surrounding the request and our policies. If a refund is approved, we will process the refund within a reasonable timeframe. Please note that the processing time may vary depending on the payment method and financial institution."
      },
      {
        "title": "5. Contact Us",
        "content": "If you have any questions or concerns about this Refund Policy, please contact Imam Ahmed Raza Trust."
      },
      {
        "title": "6. Changes to this Refund Policy",
        "content": "We reserve the right to update this Refund Policy. Any changes will be effective upon posting the revised policy on our website. By making a donation to Imam Ahmed Raza Trust, you acknowledge that you have read and agreed to the terms of this Refund Policy."
      }
    ]
  }
}
export default RefundPolicydata